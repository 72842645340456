import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const OrdersApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getWithdrawals: builder.query<any, any>({
      query: ({ page_number, page_size, status }) => ({
        url: urlPrepare(
          `/orders/withdraws?pageNumber=${page_number}&pageSize=${page_size}&status=${status}`
        ),
        method: "GET"
      }),
      providesTags: ["Orders"]
    }),
    getWithdrawById: builder.query<any, any>({
      query: (id) => ({
        url: urlPrepare(`/orders/withdraws/${id}`),
        method: "GET"
      }),
      providesTags: ["Orders"]
    }),
    approveWithdraw: builder.mutation<
      any,
      { id: string; body: { amount: any; currency: string; note: string } }
    >({
      query: ({ id, body }) => ({
        url: urlPrepare(`/orders/withdraws/${id}/approved`),
        method: "POST",
        formData: true,
        body
      }),
      invalidatesTags: ["Orders"]
    }),
    getOrders: builder.query({
      query: (params) => urlPrepare("/orders/deposits", {}, params)
    }),
    getManualAndAutomatedOrders: builder.query({
      query: (params) => urlPrepare("/all-deposits", {}, params)
    }),
    approveDeposit: builder.mutation<any, { id: string; approvalData: any; provider: string }>({
      query: ({ id, approvalData, provider }) => {
        const formData = new FormData();
        Object.keys(approvalData).forEach((elem) => {
          formData.append(elem, approvalData[elem]);
        });
        return {
          url:
            provider !== "lean"
              ? urlPrepare(`/orders/deposits/${id}/approved`)
              : urlPrepare(`/funds/lean-deposits/${id}/confirm`),
          method: provider !== "lean" ? "POST" : "PATCH",
          body: formData
        };
      },
      invalidatesTags: ["Orders"]
    }),
    cancelLeanDeposit: builder.mutation<any, { id: string; provider: string }>({
      query: ({ id }) => ({
        url: urlPrepare("funds/lean-deposits/:id/reject", { id }),
        method: "PATCH"
      }),
      invalidatesTags: ["Orders"]
    }),
    getDepositById: builder.query({
      query: ({ id, provider }) => {
        if (provider !== "lean") {
          return urlPrepare(`/orders/deposits/${id}`);
        } else {
          return urlPrepare(`orders/lean-deposits/${id}`);
        }
      }
    })
  })
});

export const {
  useGetWithdrawalsQuery,
  useGetWithdrawByIdQuery,
  useApproveWithdrawMutation,
  useGetManualAndAutomatedOrdersQuery,
  useGetOrdersQuery,
  useApproveDepositMutation,
  useGetDepositByIdQuery,
  useCancelLeanDepositMutation
} = OrdersApi;
