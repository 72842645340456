import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ConfigProvider } from "antd";

import enUS from "antd/es/locale/en_US";
import { BrowserRouter as Router } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import { store } from "./store";

import App from "./App";

// import "react-table/react-table.css";
// import "./css/reactTable.css";

// import "./css/index.css";

import NiceModal from "@ebay/nice-modal-react";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
      retry: true
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider locale={enUS}>
          <NiceModal.Provider>
            <App />
          </NiceModal.Provider>
        </ConfigProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
