import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError, } from "@reduxjs/toolkit/query/react";
import { ApiV1BaseQuery } from "./api";


export const ApiV2BaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_HOST_V2,
  prepareHeaders: (headers, { getState }) => {
    const token = localStorage.getItem("token");
    // If we have a token set in state, let's assume that we should be passing it.
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  }
});


export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // TODO: Preventing multiple unauthorized errors
  let result = await ApiV2BaseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await ApiV1BaseQuery('/auth/token/refresh', api, extraOptions) as { data: { token: string } }
    if (refreshResult.data) {
      // Retry the initial query with the new token
      const newToken = refreshResult.data.token;
      const newExtraOptions = {
        ...extraOptions,
        headers: {
          Authorization: `Bearer ${newToken}`,
        },
      };
      result = await ApiV2BaseQuery(args, api, newExtraOptions);
    } else {
      localStorage.removeItem('token');
      window.location.href = '/';
    }
  }
  return result
}


export const SplitApiV2 = createApi({
  reducerPath: "splitApiV2",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "CurrentUser",
    "Baskets",
    "Tickers",
    "Watchlists",
    "Solutions",
    "RiskProfileMatrix",
    "ProfessionalAssetManagers",
    "Groups",
    "StrategieSettingService",
    "Users",
    "recommendations",
    "Smartfolios",
    "Smartfolio",
    "backtest",
    "Investments",
    "RebalancingRequests",
    "Dividends",
    "reports",
    "factsheet",
    "Client",
    "Orders",
    "Positions",
    "OrdersSummary",
    "Transactions",
    "reconciliations"
  ],
  endpoints: () => ({})
});
