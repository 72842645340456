import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";

import Toasts from "./containers/Toasts";
import { IsUserRedirect, ProtectedRoute } from "./helpers/routes";
import { isEmptyValue } from "./helpers";
import { useAuthListener } from "./hooks/auth";

// import "react-toastify/dist/ReactToastify.css";
// import "@ant-design/pro-components/dist/components.css";

const Dashboard = React.lazy(() => import("./containers/Dashboard"));
const Login = React.lazy(() => import("./containers/Login/Login"));

function App(props) {
  const { isLoading, isAuthenticated, setIsAuthenticated } =
    useAuthListener(props);

  useEffect(() => {
    if (isEmptyValue(props.user)) {
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
  }, [props.user, setIsAuthenticated]);

  return (
    <React.Suspense fallback={<div></div>}>
      <ToastContainer />
      <Switch>
        <IsUserRedirect
          isAuthenticated={isAuthenticated}
          isLoading={isLoading}
          path="/login"
          loggedInPath="/"
          component={Login}
        />
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          isLoading={isLoading}
          path="/"
          component={Dashboard}
        />
      </Switch>
      <Toasts />
    </React.Suspense>
  );
}

const mapStateToProps = () => (state) => ({
  user: state.auth.authenticatedUser
});

export default connect(mapStateToProps, null)(App);
