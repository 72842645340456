import { urlPrepare } from "helpers";
import { SplitApi } from "redux/api";
import {
  ActiveClient,
  Client,
  ColdClient,
  OnBoardingClient,
  RejectedAndDeletedClient
} from "./types";
import { SplitApiV2 } from "redux/apiv2";
import { isEmpty } from "lodash";

export const clientsApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getClients: builder.query<Client[], void>({
      query: () => urlPrepare(process.env.REACT_APP_CLIENTS),
      providesTags: ["Client"]
    }),
    getNewActiveClients: builder.query<any, any>({
      query: ({ page, limit, searchParams }) => {
        const full_params = { ...searchParams, page, limit };
        return urlPrepare(`list/users/active`, {}, full_params);
      },
    }),
    getNewOnboardingClients: builder.query<any, any>({
      query: ({ page, limit, searchParams }) => {
        const full_params = { ...searchParams, page, limit };
        return urlPrepare(`list/users/onboarding`, {}, full_params);
      },
    }),
    getActiveClients: builder.query<any, any>({
      query: ({ page_number, page_size, params }) => {
        const full_params = { ...params, page_number, page_size };
        return urlPrepare(`/users/active`, {}, full_params);
      },

      providesTags: ["Client"]
    }),
    getInvestmentsByClientId: builder.query<
      any,
      { clientId: string; page_number: number; page_size: number; investment_type: string }
    >({
      query: ({ clientId, page_number, page_size, investment_type }) =>
        urlPrepare(
          "/users/:userID/investments",
          { userID: clientId },
          { page_number, page_size, investment_type }
        ),
      providesTags: ["Client"]
    }),

    getRejectedAndDeletedClients: builder.query<RejectedAndDeletedClient[], void>({
      query: () => urlPrepare(process.env.REACT_APP_REJECTED_AND_DELETED_CLIENTS),
      providesTags: ["Client"]
    }),
    getOnBoardingClients: builder.query<OnBoardingClient[], void>({
      query: () => urlPrepare(process.env.REACT_APP_ONBOADDING_CLIENTS),
      providesTags: ["Client"]
    }),
    getClient: builder.query<Client, string>({
      query: (id) => urlPrepare(process.env.REACT_APP_CLIENT_INFO, { id }),
      providesTags: ["Client"]
    }),
    getClientsByManagerId: builder.query<Client[], string>({
      query: (managerId) => `${process.env.REACT_APP_CLIENTS_BY_MANAGER_ID}?managerId=${managerId}`,
      providesTags: ["Client"]
    }),
    updateClientManagerId: builder.mutation<
      Client,
      { clientId: string; managerId: string; newManagerId: string }
    >({
      query: ({ clientId, managerId, newManagerId }) => ({
        url: urlPrepare(process.env.REACT_APP_UPDATE_CLIENT_MANAGER_ID),
        method: "PUT",
        body: {
          clientId: clientId,
          managerId: managerId,
          newManagerId: newManagerId
        }
      }),
      invalidatesTags: ["Client"]
    }),
    addClient: builder.mutation<Client, Client>({
      query: (data) => ({
        url: urlPrepare(process.env.REACT_APP_ADD_CLIENT),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: data
      }),
      invalidatesTags: ["Client"]
    }),
    enableClient: builder.query<Client, string>({
      query: (id) => urlPrepare(process.env.REACT_APP_CLIENT_ENABLE, { id }),
      providesTags: ["Client"]
    }),
    disableClient: builder.query<Client, string>({
      query: (id) => urlPrepare(process.env.REACT_APP_CLIENT_DISABLE, { id }),
      providesTags: ["Client"]
    }),
    deleteClient: builder.mutation<Client, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_DELETE_CLIENT, { id }),
        method: "DELETE"
      }),
      invalidatesTags: ["Client"]
    }),
    approveClient: builder.mutation<Client, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_CLIENT_APPROVE, { id }),
        method: "POST"
      }),
      invalidatesTags: ["Client"]
    }),
    uploadFiles: builder.mutation<any, { id: string; data: any }>({
      query: ({ id, data }) => {
        const formData = new FormData();
        const { note, ...files } = data;

        Object.keys(files).forEach((name) => {
          for (let i = 0; i < files[name].length; i++) {
            formData.append(name, files[name][i]);
          }
        });
        formData.append("note", note);
        return {
          url: urlPrepare(process.env.REACT_APP_CLIENT_UPLOAD, { id }),
          method: "POST",
          body: formData
        };
      },
      invalidatesTags: ["Client"]
    }),
    uploadPassport: builder.mutation<any, { id: string; files: any }>({
      query: ({ id, files }) => {
        const { passport } = files;
        const formData = new FormData();
        formData.append("passport", passport);
        return {
          url: urlPrepare(process.env.REACT_APP_CLIENT_PASSPORT_UPLOAD, { id }),
          method: "POST",
          body: formData
        };
      },
      invalidatesTags: ["Client"]
    }),
    editClientType: builder.mutation<Client, { id: string; formData: Client }>({
      query: ({ id, formData }) => ({
        url: urlPrepare(process.env.REACT_APP_CLIENT_EDIT_TYPE, { id }),
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        body: formData
      }),
      invalidatesTags: ["Client"]
    }),
    editClientRiskGrade: builder.mutation<Client, { id: string; formData: Client }>({
      query: ({ id, formData }) => ({
        url: urlPrepare(process.env.REACT_APP_CLIENT_EDIT_RISKGRADE, { id }),
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData
      }),
      invalidatesTags: ["Client"]
    }),
    updateAdminForm: builder.mutation<any, { id: string; formData: any }>({
      query: ({ id, formData }) => ({
        url: urlPrepare(process.env.REACT_APP_UPDATE_ADMIN_FORM, { id }),
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: formData
      }),
      invalidatesTags: ["Client"]
    }),
    addComment: builder.mutation<
      any,
      {
        id: string;
        comment: any;
      }
    >({
      query: ({ id, comment }) => ({
        url: urlPrepare(process.env.REACT_APP_ADD_COMMENT, { id }),
        method: "POST",
        body: comment
      }),
      invalidatesTags: ["Client"]
    }),
    updateComplianceSteps: builder.mutation<any, { id: any; steps: any }>({
      query: ({ id, steps }) => ({
        url: urlPrepare(process.env.REACT_APP_UPDATE_COMPLIANCE_STEPS, { id }),
        method: "POST",
        body: steps
      }),
      invalidatesTags: ["Client"]
    }),
    deleteComment: builder.mutation<any, { id: string | undefined; commentId: string }>({
      query: ({ id, commentId }) => ({
        url: urlPrepare(process.env.REACT_APP_DELETE_COMMENT, { id }),
        method: "PUT",
        body: { commentId }
      }),
      invalidatesTags: ["Client"]
    }),
    updateClientProfile: builder.mutation<Client, { id: string | undefined; formData: any }>({
      query: ({ id, formData }) => ({
        url: urlPrepare(process.env.REACT_APP_UPDATE_PROFILE, { id }),
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token")
        },
        body: formData
      }),
      invalidatesTags: ["Client"]
    }),
    emailAdminForm: builder.mutation<any, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: urlPrepare(process.env.REACT_APP_EMAIL_ADMIN_FORM, { id }),
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body
      }),
      invalidatesTags: ["Client"]
    }),
    resetClientPassword: builder.mutation<Client, { id: string; body: Client }>({
      query: ({ id, body }) => ({
        url: urlPrepare(process.env.REACT_APP_CLIENT_RESET_PASSWORD, { id }),
        method: "POST",
        body,
        responseHandler: (response) => response.text()
      }),

      invalidatesTags: ["Client"]
    }),
    validateClientFiles: builder.mutation<any, string>({
      query: (clientId) => ({
        url: `${process.env.REACT_APP_API_HOST}/clients/${clientId}/updateOnboardingStep`,
        method: "POST",
        body: {
          step: "PROOF_OF_ADDRESS"
        }
      }),
      invalidatesTags: ["Client"]
    }),
    toggleClientCold: builder.mutation<ColdClient, { id: string; isCold: boolean }>({
      query: ({ id, isCold }) => ({
        url: `${process.env.REACT_APP_CLIENTS}/${id}/toggle-cold`,
        method: "PUT",
        body: {
          isCold
        }
      }),
      invalidatesTags: ["Client"]
    }),
    getColdClients: builder.query<ColdClient, void>({
      query: () => urlPrepare(process.env.REACT_APP_COLD_CLIENTS),
      providesTags: ["Client"]
    }),
    enableClientStatus: builder.mutation<any, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_CLIENT_ENABLE_STATUS, { id }),
        method: "PUT"
      }),
      invalidatesTags: ["Client"]
    }),
    disableClientStatus: builder.mutation<any, string>({
      query: (id) => ({
        url: urlPrepare(process.env.REACT_APP_CLIENT_DISABLE_STATUS, { id }),
        method: "PUT"
      }),
      invalidatesTags: ["Client"]
    }),
    sendResetPasswordLink: builder.mutation<any, string>({
      query: (id) => ({
        url: `${process.env.REACT_APP_CLIENTS}/${id}/sendResetPasswordLink`,
        method: "POST"
      })
    }),
    getClientsSummaryOverview: builder.query<any, any>({
      query: ({ clientId }) =>
        urlPrepare(process.env.REACT_APP_CLIENTS_SUMMARY_OVERVIEW, {}, { userID: clientId })
    }),
    getClientsPositionsOverview: builder.query<any, any>({
      query: ({ clientId }) =>
        urlPrepare(process.env.REACT_APP_CLIENTS_POSITIONS_OVERVIEW, {}, { userID: clientId })
    }),
    getInvestmentsStatus: builder.query<any, { clientExternalId: number }>({
      query: ({ clientExternalId }) =>
        urlPrepare(process.env.REACT_APP_ACCOUNT_STATUS, { user_id: clientExternalId })
    })
  })
});

export const {
  useGetClientsQuery,
  useGetInvestmentsStatusQuery,
  useGetActiveClientsQuery, // deprecated
  useGetNewActiveClientsQuery, // new get active clients query
  useGetRejectedAndDeletedClientsQuery,
  useGetOnBoardingClientsQuery, // deprecated
  useGetNewOnboardingClientsQuery, // new get onboarding clients query
  useGetClientQuery,
  useGetClientsByManagerIdQuery,
  useUpdateClientManagerIdMutation,
  useAddClientMutation,
  useEnableClientQuery,
  useDisableClientQuery,
  useDeleteClientMutation,
  useApproveClientMutation,
  useUploadFilesMutation,
  useUploadPassportMutation,
  useEditClientTypeMutation,
  useEditClientRiskGradeMutation,
  useUpdateAdminFormMutation,
  useAddCommentMutation,
  useUpdateComplianceStepsMutation,
  useDeleteCommentMutation,
  useUpdateClientProfileMutation,
  useEmailAdminFormMutation,
  useResetClientPasswordMutation,
  useValidateClientFilesMutation,
  useToggleClientColdMutation,
  useGetColdClientsQuery,
  useEnableClientStatusMutation,
  useDisableClientStatusMutation,
  useSendResetPasswordLinkMutation,
  useGetInvestmentsByClientIdQuery,
  useGetClientsSummaryOverviewQuery,
  useGetClientsPositionsOverviewQuery
} = clientsApi;
